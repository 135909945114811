import i18n from "i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import {initReactI18next} from "react-i18next";

i18n
    .use(LanguageDetector)
    .use(initReactI18next)
    .init({
        // we init with resources
        resources: {
            en: {
                translations: {
                    event: "EVENTS",
                    about: "ABOUT US",
                    gallery: "GALLERY",
                    onPress: "ON PRESS",
                    contact: "CONTACT",
                    allEvents: "All Events",
                    records: "Records",
                    contactUsHeader: "Contact Us",
                    messageHeader: "Message",
                    aboutHeader1: 'Having hosted more than 800 concerts and leading jazz festivals such as Akbank Jazz Festival and XJAZZ since its establishment three years ago, BOVA has quickly become one of the important alternative music stages of Istanbul, and especially the project and music scene that jazz musicians call “our home”. It has become a venue where they develop their albums.',
                    aboutHeader2: 'On our way to becoming an accessible venue where Turkey\'s leading jazz musicians can share their original music and shape their new projects, the position we have reached in the Turkish and international jazz scene in a short time is pleasing, thanks to loyal listeners, musicians and Beyoğlu\'s usual activity.',
                    aboutHeader3: 'Some Musicians Have Played in Bova',
                    subscriptionDesc: 'Subscribe to be informed about important events and news!',
                    subscriptionHeader: "Subscribe Now",
                    subscriptionName: "Your Name",
                    successMail: "Thank you for subscription.",
                    sendingMail: "Sending...",

                }
            },
            tr: {
                translations: {
                    event: "ETKİNLİKLER",
                    about: "KURUMSAL",
                    gallery: "GALERİ",
                    onPress: "BASINDA BİZ",
                    contact: "İLETİŞİM",
                    allEvents: "Tum Etkinlikler",
                    records: "Kayıtlar",
                    contactUsHeader: "Bizimle İletişime Geçin",
                    messageHeader: "Mesaj",
                    aboutHeader1: "Kurulduğu üç yıl öncesinden bugüne 800’den fazla konsere ve Akbank Caz Festivali, XJAZZ gibi önde gelen caz festivallerine ev sahipliği yapmış olan BOVA, kısa sürede İstanbul’un önemli alternatif müzik sahnelerinden biri oldu ve özellikle de caz müzisyenlerinin “evimiz” dedikleri, proje ve albümlerini geliştirdikleri bir mekan haline geldi.",
                    aboutHeader2: "Türkiye’nin önde gelen caz müzisyenlerinin orijinal müziklerini paylaşabildikleri, yeni projelerini şekillendirdikleri erişilebilir bir mekan olma hedefiyle çıktığımız yolda, sadık dinleyiciler, müzisyenler ve Beyoğlu'nun olağan hareketliliği sayesinde kısa süre içerisinde Türkiye ve uluslararası caz sahnesinde geldiğimiz nokta memnuniyet verici.",
                    aboutHeader3: "Bova'da Çalan Bazı Müzisyenler",
                    subscriptionHeader: "Abone Ol",
                    subscriptionDesc: "Önemli gelişme ve konserlerden haberdar olmak için abone olun!",
                    subscriptionName: "Adınız",
                    successMail: "Abone olduğunuz için teşekkür ederiz.",
                    sendingMail: "Gönderiliyor...",
                }
            }
        },
        fallbackLng: "tr",
        debug: true,

        // have a common namespace used around the full app
        ns: ["translations"],
        defaultNS: "translations",

        keySeparator: false, // we use content as keys

        interpolation: {
            escapeValue: false
        }
    });

export default i18n;