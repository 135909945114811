import React, {Component} from "react";
import MailchimpSubscribe from "react-mailchimp-subscribe";
import {Trans} from "react-i18next";

// a basic form
const CustomForm = ({status, message, onValidated, lang}) => {

    let email, name;
    const submit = () => {
        email &&
        name &&
        email.value.indexOf("@") > -1 &&
        onValidated({
            EMAIL: email.value,
            NAME: name.value
        });
    }
    return (
        <div
            style={{
                background: "#efefef",
                borderRadius: 2,
                padding: 10,
                width:'80%'
            }}
        >
            {status === "sending" && <div style={{color: "blue"}}>
                <Trans i18nKey="sendingMail">

                </Trans>
            </div>}
            {status === "error" && (
                <div
                    style={{color: "red"}}
                    dangerouslySetInnerHTML={{__html: message}}
                />
            )}
            {status === "success" && (
                <div>
                    <p>
                        <Trans i18nKey="successMail">

                        </Trans>
                    </p>
                </div>
            )}
            <div className="form" >
                    <input
                        name="FNAME"
                        className="form__email"
                        ref={node => (name = node)}
                        type="text"
                        placeholder={lang === "tr" ? `Adınız` : `Your Name`}
                    />
                    <input
                        ref={node => (email = node)}
                        className="form__email m-1"
                        type="email"
                        name="EMAIL"
                        placeholder="Email"
                    />
                    <button
                        className="form__button"
                        onClick={submit}>
                        <Trans i18nKey="subscriptionHeader">
                        </Trans>
                    </button>
            </div>
        </div>
    );
};

export default class Demo extends Component {
    render() {
        const url = process.env.REACT_APP_MAILCHIMP_URL;
        const lang = this.props.lang;
        return (
            <div className="subscribe" >
                <h2 className="subscribe__title text-black">
                    <Trans i18nKey="subscriptionHeader">
                    </Trans>
                </h2>
                <p className="subscribe__copy text-black">
                    <Trans i18nKey="subscriptionDesc">
                    </Trans>
                </p>
                {lang && <MailchimpSubscribe
                    url={url}
                    render={({subscribe, status, message}) => (
                        <CustomForm
                            status={status}
                            message={message}
                            onValidated={formData => subscribe(formData)}
                            lang={lang}
                        />
                    )}
                />}
            </div>
        );
    }
}