import React from 'react'
import {Link} from "react-router-dom";
import "bootstrap/js/src/collapse.js";
import { Trans } from "react-i18next";
import LogoWhitePng from '../../../assets/images/LOGO_W.png'
import {AiFillFacebook} from 'react-icons/ai'
import {FaInstagramSquare} from 'react-icons/fa'
import {BsTwitch} from 'react-icons/bs'
import {IoLogoYoutube} from 'react-icons/io'
import '../../../assets/css/menuContent.css'
import {useWindowSize} from "../../../static/hooks";

const Navbar = ({changeLanguage}) => {
    const size = useWindowSize()


    return (
        <header className={`site-header active`}>
            <nav className="navbar navbar-right navbar-expand-lg navbar-dark">
                <div className="container-fluid d-flex">
                    <div className="logo">
                        <Link to="/">
                            <object aria-label="logo-white" style={{overflow: 'hidden'}} data={LogoWhitePng} width="70" height="70"/>
                        </Link>
                    </div>
                    <div className="nav-group-wrapper ml-auto">
                        <button className="navbar-toggler" type="button" data-bs-toggle="collapse"
                                data-bs-target="#navbarContent" aria-controls="navbarSupportedContent"
                                aria-expanded="false"
                                aria-label="Toggle navigation">
                            <span className="navbar-toggler-icon"></span>
                        </button>

                        {size.width >= 680 && <div className="collapse navbar-collapse" id="navbarContent">
                            <ul className="navbar-nav" style={{color: 'white', marginRight: '2rem'}}>
                                <li className="nav-item m-2">
                                    {/*<div className=:>*/}
                                    <Link to="/takvim">
                                        <Trans i18nKey="event">
                                        </Trans>
                                    </Link>
                                    &nbsp;&nbsp;{"|"}
                                </li>
                                <li className="nav-item m-2">
                                    {/*<div className=:>*/}
                                    <Link to="/kurumsal">
                                        <Trans i18nKey="about">
                                        </Trans>
                                    </Link>
                                    &nbsp;&nbsp;{"|"}
                                </li>
                                <li className="nav-item m-2">
                                    <Link to="/galeri">
                                        <Trans i18nKey="gallery">
                                        </Trans>
                                    </Link>
                                    &nbsp;&nbsp;&nbsp;{"|"}
                                </li>
                                <li className="nav-item m-2">
                                    <Link to="/blog">
                                        <Trans i18nKey="onPress">
                                        </Trans>
                                    </Link>
                                    &nbsp;&nbsp;&nbsp;{"|"}
                                </li>
                                <li className="nav-item m-2">
                                    <Link to="/iletisim">
                                        <Trans i18nKey="contact">
                                        </Trans>
                                    </Link>
                                </li>
                            </ul>
                        </div>
                        }
                    </div>
                    <div className="language-bar-2">
                        {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                        <a
                            rel="noreferrer"
                            href="#"
                            className="responsive-language responsive-language-custom"
                            onClick={() => changeLanguage("tr")}
                            style={{
                                fontSize: '12px',
                                color: 'lightgray'
                            }}>
                            TR
                        </a>
                        {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                        <a
                            className="responsive-language responsive-language-custom"
                            onClick={() => changeLanguage("en")}
                            rel="noreferrer"
                            href="#"
                            style={{
                                fontSize: '12px',
                                color: 'lightgray',
                            }}>
                            EN
                        </a>
                        <a
                            className="responsive-logo header-fab-icon fab fa-instagram"
                            href="https://www.instagram.com/bova_sahne/"
                            target="_blank"
                            rel="noopener noreferrer">
                            <FaInstagramSquare/>
                        </a>
                        <a
                            className="responsive-logo header-fab-icon"
                            href="https://www.facebook.com/bovasahne"
                            target="_blank"
                            rel="noopener noreferrer">
                            <AiFillFacebook/>
                        </a>
                        <a
                            className="responsive-logo header-fab-icon fab fa-instagram"
                            href="https://www.twitch.tv/bova_live_"
                            target="_blank"
                            rel="noopener noreferrer">
                            <BsTwitch/>
                        </a>
                        <a
                            className="responsive-logo header-fab-icon fa-inverse fab fa-youtube"
                            href="https://www.youtube.com/@bovasahne8821"
                            target="_blank"
                            rel="noopener noreferrer">
                            <IoLogoYoutube/>
                        </a>
                    </div>
                </div>
                {size.width < 680 && <div className="collapse navbar-collapse" id="navbarContent">
                    <ul className="navbar-nav" style={{color: 'white', marginRight: '2rem'}}>
                        <li className="nav-item m-2">
                            {/*<div className=:>*/}
                            <Link to="/takvim" >
                                <Trans i18nKey="event">
                                </Trans>
                            </Link>
                            &nbsp;&nbsp;{"|"}
                        </li>
                        <li className="nav-item m-2">
                            {/*<div className=:>*/}
                            <Link to="/kurumsal">
                                <Trans i18nKey="about">
                                </Trans>
                            </Link>
                            &nbsp;&nbsp;{"|"}
                        </li>
                        <li className="nav-item m-2">
                            <Link to="/galeri">
                                <Trans i18nKey="gallery">
                                </Trans>
                            </Link>
                            &nbsp;&nbsp;&nbsp;{"|"}
                        </li>
                        <li className="nav-item m-2">
                            <Link to="/blog">
                                <Trans i18nKey="onPress">
                                </Trans>
                            </Link>
                            &nbsp;&nbsp;&nbsp;{"|"}
                        </li>
                        <li className="nav-item m-2">
                            <Link to="/iletisim">
                                <Trans i18nKey="contact">
                                </Trans>
                            </Link>
                        </li>
                    </ul>
                </div>
                }
            </nav>
        </header>
    )
}


export default Navbar