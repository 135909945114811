import React, {useEffect, useState} from 'react'
import {Gallery} from "react-grid-gallery";
import Lightbox from "react-image-lightbox";
import "react-image-lightbox/style.css";
import {motion} from "framer-motion";
// import {images} from "../Components/Gallery/GalleryTest";
import '../assets/css/gallery.css'
import {protectedEnpointPostRequest} from "../static/functions";
import {mutateImageUrl} from "../static/helpers";
import {documentToReactComponents} from "@contentful/rich-text-react-renderer";
import Navbar from "../Components/Layout/Header/Navbar";
import Layout from "../Components/Layout/Layout";

const GalleryTest = () => {
    const [index, setIndex] = useState(-1);
    const [imageUrls, setImageUrls] = useState([])
    const [title, setTitle] = useState(null)
    const [desc, setDesc] = useState(null)

    useEffect(() => {
        getConcerts()
    }, [])

    async function getConcerts() {
        const body = JSON.stringify({query});
        const token = process.env.REACT_APP_ACCESS_TOKEN;
        const response = await protectedEnpointPostRequest(
            `${process.env.REACT_APP_CONTENTFUL_API}/${process.env.REACT_APP_CONTENTFUL_SPACE_ID}/`,
            body,
            token
        )
        if (response) {
            setImageUrls([])
            var rawImageData = response.data.data.photosCategoryCollection.items[0].galleryCollection.items
            setDesc(response.data.data.photosCategoryCollection.items[0].desc.json)
            setTitle(response.data.data.photosCategoryCollection.items[0].title)
            for (let i = 0; i < rawImageData.length; i++) {

                // const sizes = getImageSizeFromUrl(rawImageData[i].url)
                setImageUrls(elem => [...elem, {
                    // THUMBNAIL IMAGES SHOULD BE ON SRC ATTRIBUTE
                    src: mutateImageUrl(rawImageData[i].url, rawImageData[i].width, rawImageData[i].height),
                    original: rawImageData[i].url,
                    width: rawImageData[i].width,
                    height: rawImageData[i].height,
                    caption: i.toString(),
                }])
            }
        }
    }

    const currentImage = imageUrls[index];
    const nextIndex = (index + 1) % imageUrls.length;
    const nextImage = imageUrls[nextIndex] || currentImage;
    const prevIndex = (index + imageUrls.length - 1) % imageUrls.length;
    const prevImage = imageUrls[prevIndex] || currentImage;

    const handleClick = (index, item) => setIndex(index);
    const handleClose = () => setIndex(-1);
    const handleMovePrev = () => setIndex(prevIndex);
    const handleMoveNext = () => setIndex(nextIndex);
    return (
        <Layout>
            <Navbar/>

            <div className="gallery-container m-auto">

                {/*<h2>*/}
                <div className="section-head m-4">
                    {title && (
                        <h2>{title}</h2>
                    )}
                </div>
                {/*</h2>*/}
                <div className="m-4">
                    {desc && documentToReactComponents(desc)}
                </div>
                {imageUrls &&
                    (<motion.div
                        // className="text-center"
                        initial={{opacity: 0}}
                        animate={{opacity: 1}}
                        exit={{opacity: 0}}
                        transition={{duration: 1}}
                        className="gallery-wrapper"
                    >
                        {/*<div>*/}
                        <Gallery
                            images={imageUrls}
                            onClick={handleClick}
                            enableImageSelection={false}
                        />
                        {!!currentImage && (
                            <Lightbox
                                mainSrc={currentImage.original}
                                imageTitle={currentImage.caption}
                                mainSrcThumbnail={currentImage.src}
                                nextSrc={nextImage.original}
                                nextSrcThumbnail={nextImage.src}
                                prevSrc={prevImage.original}
                                prevSrcThumbnail={prevImage.src}
                                onCloseRequest={handleClose}
                                onMovePrevRequest={handleMovePrev}
                                onMoveNextRequest={handleMoveNext}
                                animationDuration={800}
                            />
                        )}</motion.div>)}
            </div>
        </Layout>
    )
}

export default GalleryTest


const query = `
query PhotosPageQuery
    {
        photosCategoryCollection(where : {title : "Uygar Bulut Photography"}) {
    items {
      title
      desc {
        json
      }
      galleryCollection{
        items{
          url
          width
          height
        }
      }
    }
  }
    }
`