import React, {useEffect, useState} from 'react'
import {useLocation} from "react-router-dom";
import { useTranslation } from "react-i18next";
import Layout from '../Components/Layout/Layout'
import MultipleItems from "../Components/Index/Recordings";
import CalendarIndex from "../Components/Calendars/CalendarIndex";
import '../assets/css/index.css'
import {protectedEnpointPostRequest} from "../static/functions";
import SubscribeForm from "../Components/Form/FormTest2";
import {useWindowSize} from "../static/hooks";
import MainImage from '../assets/images/cover_1.jpg'
import BlogListSmall from "../Components/Blog/BlogListSmall";
import InstagramFeed from "../Components/Instagram/InstagramFeed";
import Navbar from "../Components/Layout/Header/Navbar";


const Index = () => {
    const body = JSON.stringify({query});
    const token = process.env.REACT_APP_ACCESS_TOKEN;
    const [events, setEvents] = useState([])
    const [event, setEvent] = useState([])
    const [blogs, setBlogs] = useState([])
    const [lang, setLang] = useState()

    const { i18n } = useTranslation();

    const changeLanguage = lng => {
        i18n.changeLanguage(lng);

        setLang(lng)
    };
    useEffect(() => {

        if(i18n.language === "tr-TR")
            setLang('tr')
        else
            setLang('en')
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[])

    // reservation anchor work around
    const {state} = useLocation();

    // section-2 - left side size work around
    const size = useWindowSize()

    useEffect(() => {
        getConcerts()
        if (state === 'rezervasyon') {
            window.location.hash = '#rezervasyon'
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    async function getConcerts() {
        const response = await protectedEnpointPostRequest(
            `${process.env.REACT_APP_CONTENTFUL_API}/${process.env.REACT_APP_CONTENTFUL_SPACE_ID}/`,
            body,
            token
        )

        console.log('desc test ', response.data)
        if (response) {
            setEvents(response.data.data.concertCollection.items)
            setBlogs(response.data.data.blogsCollection.items)
        }
    }

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    return (
        <Layout>
            <Navbar changeLanguage={changeLanguage}/>
            <div className="m-auto  ">
                <div className="section-1">
                    <div className="image-wrapper">
                        <img src={MainImage} alt=""/>
                    </div>
                </div>
                <div className="row no-gutter m-auto">
                    <div className={`section-2 col-md-8 ${size.width > 680 ? `m-5` : `p-5`}`}>
                        <div className="col-md-11 m-4 m-auto">
                            <div className="mt-5 row">
                                {events &&
                                    <CalendarIndex
                                        events={events}
                                        setEvent={setEvent}
                                        event={event}/>}
                            </div>
                            <div className="mt-5 mb-5 row m-auto">
                                <MultipleItems/>
                            </div>
                        </div>
                    </div>

                    <div className="section-4 col-md-3 mt-5">
                        <div>
                            <BlogListSmall blogs={blogs}/>
                        </div>
                        <div>
                            {/*<div className="section-4 col-md-3 mt-5">*/}
                            <InstagramFeed/>
                        </div>
                    </div>

                    {/*<div id="rezervasyon" className="section-4 col-md-3 mt-5">*/}
                    {/*    <div>*/}
                    {/*        {events &&*/}
                    {/*            <Forms*/}
                    {/*                events={events}*/}
                    {/*                handleShow={handleShow}*/}
                    {/*                setEvent={setEvent}*/}
                    {/*                event={event}*/}
                    {/*                status={status}*/}
                    {/*                setStatus={setStatus}*/}
                    {/*            />}*/}
                    {/*    </div>*/}
                    {/*</div>*/}
                </div>
                <div className="section-4 m-5 mt-5 row m-auto">
                    <div className="col-md-11 m-auto bg-white" style={{borderRadius: '12px'}}>
                        {lang && <SubscribeForm lang={lang}/>}
                    </div>
                </div>
            </div>
            {/*<div>*/}
            {/*<BuyTicketModal event={event} handleClose={handleClose} show={show}/>*/}
            {/*</div>*/}

        </Layout>
    )
}

export default Index

const query = `
{
  concertCollection(order: testDate_ASC, limit: 5) {
    items {
      bandName
      table
      testDate
      resLimit
      price
      desc{
        json
      }
      studentPrice
      coverImage {
        title
        url(transform: { width: 220, height: 250 })
      }
    }
  }
  blogsCollection {
    items {
      title
      slug
      description
      articleDate
      featureImage {
        url
      }
    }
  }
}`
