import React, {useEffect} from 'react'
import Layout from "../Components/Layout/Layout";
import Navbar from "../Components/Layout/Header/Navbar";
import '../assets/css/about.css'
import {useWindowSize} from "../static/hooks";
import {Trans, useTranslation} from "react-i18next";

const About = () => {
    useEffect(() => {
        var path = window.location.pathname
        if (path === "/bova-new/kurumsal") {
            // var ell = document.getElementsByTagName('body')
            document.body.backgroundImage = '../images/bova-about-back.png'

            // console.log(ell)
        }
        //
    }, [])

    const sizes = useWindowSize()

    const { i18n } = useTranslation();

    const changeLanguage = lng => {
        i18n.changeLanguage(lng);
    };

    const musicianList = [
        "Adem Gülşen",
        "Ali Perret",
        "Alp Ersönmez",
        "Alper Yılmaz",
        "Apostolos Sideris",
        "Barış Doğukan Yazıcı",
        "Barış Ertürk",
        "Bilal Karaman",
        "Can Çankaya",
        "Cem Aksel",
        "Cem Tuncer",
        "Cengiz Baysal",
        "Cenk Erdoğan",
        "Çağıl Kaya",
        "Darrell Green",
        "Deniz Güngören",
        "Ece Göksu",
        "Ediz Hafızoğlu",
        "Emin Fındıkoğlu",
        "Engin Receoğulları",
        "Ercüment Orkut",
        "Ferit Odman",
        "İmer Demirer",
        "İzzet Kızıl",
        "Kaan Bıyıkoğlu",
        "Kağan Yıldız",
        "Korhan Futacı",
        "Mehmet İkiz",
        "Meriç Demirkol",
        "Neşet Ruacan",
        "Nilüfer Verdi",
        "Orhan Deniz",
        "Ricky Ford",
        "Sarp Maden",
        "Selen Gülün",
        "Serhan Erkol",
        "Sibel Köse",
        "Sinan Altıparmak",
        "Siney Yılmaz",
        "Şenova Ülker",
        "Şevket Akıncı",
        "Tamer Temel",
        "Tolga Bilgin",
        "Tolga Tüzün",
        "Turgut Alp Bekoğlu",
        "Volkan Hürsever",
        "Volkan Topakoğlu",
        "Volkan Öktem",
    ]
    return (
        <Layout>
            <Navbar changeLanguage={changeLanguage}/>
            <div id="about-wrapper"></div>
            <div className="about-container">
                {/*<div className=" about-section-1">*/}
                <div className="about-section about-section-wrapper">
                    <div className="row d-flex  justify-content-center">
                        <div className="col-md-2 m-5 d-flex justify-content-center align-items-center">
                            <h2 className="about-header-bova text-white" style={{fontSize: '50px', lineHeight: '4rem'}}>
                                BOVA SAHNE
                            </h2>
                        </div>
                        <div className="col-md-5 m-5 d-flex justify-content-center align-items-center">
                            <p className="m-4 text-white" style={{fontSize: '23px', lineHeight: '2rem'}}>
                                <Trans i18nKey="aboutHeader1">
                                </Trans>
                            </p>
                        </div>
                    </div>
                </div>
                <div className="about-section about-section-wrapper">
                    <div className="row d-flex  justify-content-center">
                        <div className="col-md-8 m-5 d-flex justify-content-center align-items-center">
                            <p className="m-4 text-white" style={{fontSize: '23px', lineHeight: '2rem'}}>
                                <Trans i18nKey="aboutHeader2">
                                </Trans>
                            </p>
                        </div>
                    </div>
                </div>
                <div className="about-section about-section-wrapper">
                    <div className="row d-flex text-center justify-content-center align-items-center">
                        <h3 className="text-white" style={{color:'white'}}>
                            <Trans i18nKey="aboutHeader3">
                            </Trans>
                        </h3>
                        <div className="col-md-12 m-5 d-flex justify-content-center align-items-center"
                             style={{
                                 flexWrap: 'wrap'
                             }}>

                            {/*<ul className={`col-md-3 list-common ${sizes.width < 680 ? ` mobile-artists ` : ` ` }`}>*/}
                            {sizes.width > 680
                                ?
                                (<div className="text-white row artists-wrapper">
                                    <ul className={`col-md-3 list-common`}>
                                        {musicianList.slice(0, 13).map((item, i) => {
                                            return (
                                                <li className="text-white" key={i}>
                                                    {item}
                                                </li>
                                            )
                                        })}
                                    </ul>
                                    <ul className="col-md-3 list-common">
                                        {musicianList.slice(13, 26).map((item, i) => {
                                            return (
                                                <li className="text-white m-1" key={i}>
                                                    {item}
                                                </li>
                                            )
                                        })}
                                    </ul>
                                    <ul className="col-md-3 list-common">
                                        {musicianList.slice(26, 39).map((item, i) => {
                                            return (
                                                <li className="text-white" key={i}>
                                                    {item}
                                                </li>
                                            )
                                        })}
                                    </ul>
                                    <ul className="col-md-3 list-common">
                                        {musicianList.slice(39, 52).map((item, i) => {
                                            return (
                                                <li className="text-white" key={i}>
                                                    {item}
                                                </li>
                                            )
                                        })}
                                    </ul>
                                </div>)
                                :
                                (<div className="text-white row artists-wrapper m-5">
                                    <ul className="col-md-3 list-common artists-mobile m-5">
                                        {musicianList.map((item, i) => {
                                            return (
                                                <li className="text-white m-1" key={i}>
                                                    {item}
                                                </li>
                                            )
                                        })}
                                    </ul>
                                </div>)

                            }
                        </div>
                    </div>
                </div>
            </div>
        </Layout>
    )
}

export default About