import React, {useEffect, useState} from 'react'
import Layout from "../Components/Layout/Layout";
import '../assets/css/contact.css'
import Navbar from "../Components/Layout/Header/Navbar";
import {Trans, useTranslation} from "react-i18next";

const ContactForm = () => {
    // eslint-disable-next-line no-unused-vars
    const [formStatus, setFormStatus] = React.useState('Gönder')
    const [lang, setLang] = useState(null)


    const hrStyle = {
        color: "#000000",
        backgroundColor: "#000000",
        height: 1,
        borderColor: "#000000",
        width: '100%',
        minWidth:'100%'
    }

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    const { i18n } = useTranslation();

    const changeLanguage = lng => {
        i18n.changeLanguage(lng);
        if(lng === "en")
            setLang("en")
        else
            setLang("tr")
    };
    return (
        <Layout>
            <Navbar changeLanguage={changeLanguage}/>
            <div  style={{marginTop:'7rem'}}></div>
            <div className="row contant-container m-auto mb-5">
                <div className="col-md-8">
                    <div className="container mt-5">
                        <h2 className="mb-3 text-white">
                            <Trans i18nKey="contactUsHeader">
                            </Trans>
                        </h2>
                        <form action="https://public.herotofu.com/v1/357d9c10-1853-11ed-98e8-53e0970ab51c" method="post">
                            <div className="text-white row">
                                <div className="col text-white">
                                    <input
                                        type="text"
                                        name="name"
                                        className="form-control text-white"
                                        placeholder={`${lang === 'tr' ? "Adınız" : " Your Name"}`}
                                    />
                                </div>
                                <div className="col">
                                    <input
                                        type="text"
                                        name="email"
                                        className="form-control text-white"
                                        placeholder="Email"/>
                                </div>
                            </div>
                            <div className="mb-3">
                                <label className="form-label text-white" htmlFor="message">
                                    <Trans i18nKey="messageHeader">

                                    </Trans>
                                </label>
                                <textarea
                                    placeholder={`${lang === 'tr' ? "Mesajınız" : " Your Message"}`}
                                    name="message"
                                    id="message"
                                    className="form-control text-white"
                                    style={{height: '100%'}} rows="7"
                                          required/>
                            </div>
                            <button className="btn btn-danger" type="submit">
                                {formStatus}
                            </button>
                        </form>
                    </div>
                </div>
                <div className="col-md-4">
                    <div className="m-5">
                        <div className="row text-white">
                            <h5 className=" text-white">Email</h5>
                            <a href="mailto:bovasahneinfo@gmail.com" className="contact-form-link text-white" rel="noreferrer"
                               target="_blank">bovasahneinfo@gmail.com</a>
                        </div>
                        <hr style={hrStyle}/>
                        <div className="row" >
                            <h5 className=" text-white">
                            {`${lang === 'tr' ? "Telefon Numarası" : " Phone Number"}`}
                            </h5>
                            <a href="tel:902122434461" rel="noreferrer" target="_blank" className="contact-form-link text-white">(0212) 243 44 61</a>
                        </div>
                        <hr style={hrStyle}/>
                        <div className="row">
                            <h5 className=" text-white">
                                {`${lang === 'tr' ? "Adres " : " Address"}`}
                            </h5>
                            <a href="https://g.page/bovasahne?share" rel="noreferrer" target="_blank" className="contact-form-link text-white">
                                Şehit Muhtar, Mis
                                Sk. No:17, 34435 Beyoğlu/İstanbul</a>
                        </div>
                    </div>
                </div>
            </div>
        </Layout>
    )
}
export default ContactForm