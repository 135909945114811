import {BaseURLContext} from "./baseURL-context";
import {useState} from "react";
import {
    Routes,
    Route,
} from "react-router-dom";
import {AnimatePresence} from "framer-motion";
import 'bootstrap/dist/css/bootstrap.min.css';
import CalendarNew from "./pages/Calendar";
import Index from './pages/Index.js'
import Concerts from "./Components/Concerts/Concerts";
import Gig from "./Components/Concerts/Gig";
import Footer from "./Components/Footer";
import Blogs from "./pages/Blogs";
import Blog from "./pages/Blog";
import BlogList from "./pages/BlogList";
import GalleryTest from "./pages/GalleryTest";
import './App.css';
import './assets/css/main.css';
import Contact from "./pages/Contact";
import About from "./pages/About";
import './i18n'
// import Menu from "./pages/Menu";
import TempMain from "./pages/tempMain";
import MenuPdf from "./pages/MenuPdf";


function App() {
    const [baseURL, setBaseURL] = useState(process.env.REACT_APP_CONTENTFUL_API);
    const baseURLValue = {baseURL, setBaseURL};

    return (
        <AnimatePresence exitBeforeEnter>
            <div className="app">
                <BaseURLContext.Provider value={baseURLValue}>
                    {/*<BrowserRouter>*/}
                        <Routes>
                            <Route path="/" element={<TempMain/>}/>
                            <Route path="/test" element={<Index/>}/>
                            <Route path="/takvim" element={<CalendarNew/>}/>
                            <Route path="/galeri" element={<GalleryTest/>}/>
                            <Route path="/iletisim" element={<Contact/>}/>
                            <Route path="/kurumsal" element={<About/>}/>
                            {/*<Route path="/menu" element={<Menu/>}/>*/}
                            <Route path="/menu" element={<MenuPdf/>}/>
                            <Route path="/gig" element={<Concerts/>}>
                                <Route path="/gig/:slug" element={<Gig/>}/>
                            </Route>
                            <Route path="" element={<Blogs/>}>
                                <Route path="/blog" element={<BlogList/>}/>
                                <Route path="/blog/:slug" element={<Blog/>}/>
                            </Route>
                        </Routes>
                        <Footer/>
                    {/*</BrowserRouter>*/}
                </BaseURLContext.Provider>
            </div>
        </AnimatePresence>
    );
}

export default App;