import React, {useCallback, useEffect, useLayoutEffect, useState} from "react";
import {useNavigate} from "react-router";
import trLocale from '@fullcalendar/core/locales/tr';
import enLocale from '@fullcalendar/core/locales/en-gb';
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import {Oval} from "react-loading-icons";
import moment from "moment";
import {protectedEnpointPostRequest} from "../static/functions";
import {convertContentfulStringDateToDate, stripImageUrl} from "../static/helpers";
import Layout from "../Components/Layout/Layout";
import "@fullcalendar/daygrid/main.css";
import '../assets/css/menuContent.css'
import '../assets/css/fullCalendar.css'
import Navbar from "../Components/Layout/Header/Navbar";
import {useTranslation} from "react-i18next";


export default function Calendar() {
    // const {baseURL} = useContext(BaseURLContext);
    const [show, setShow] = useState(false)
    const [isLoading, setIsLoading] = useState(true)
    const [lang, setLang] = useState(null)
    const navigate = useNavigate();

    useEffect(() => {
        setTimeout(() => {
            setIsLoading(false)
        }, 3000)
    }, [])

    const [allMappedConcerts, setAllMappedConcerts] = useState([])
    const [visible, setVisible] = useState(false);

    const handleUserKeyPress = useCallback(event => {
        // eslint-disable-next-line no-unused-vars
        const {key, keyCode} = event;
    }, []);

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    useEffect(() => {
        var wrap = document.getElementById('root')

        wrap.addEventListener('click', event => {
            // Check if it’s the list element, we want the clicks only from them
            test2(allMappedConcerts)
        })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [handleUserKeyPress, allMappedConcerts])

    async function handleLoad() {
        // console.log('process starting')
        await getCalendar().then(arr => {
            test2(arr);
            return arr;
        });
        setVisible(true)
    }

    async function getCalendar() {
        const body = JSON.stringify({query});
        const token = process.env.REACT_APP_ACCESS_TOKEN;
        const response = await protectedEnpointPostRequest(
            `${process.env.REACT_APP_CONTENTFUL_API}/${process.env.REACT_APP_CONTENTFUL_SPACE_ID}/`,
            body,
            token
        )
        if (response) {
            console.log("tes log",response.data)
            var arr = []
            setAllMappedConcerts([])
            // setAllConcerts(response.data.data.concertCollection.items)
            for (let i = 0; i < response.data.data.concertCollection.items.length; i++) {
                const dateValues = convertContentfulStringDateToDate(response.data.data.concertCollection.items[i].testDate)
                // const dateFormatted = moment(dateValues).format('YYYY-MM-DD').toString()

                // const url = `/gig/${dateFormatted}`
                setAllMappedConcerts(elem => [...elem,
                        {
                            title: response.data.data.concertCollection.items[i].bandName,
                            date: dateValues,
                            link: response.data.data.concertCollection.items[i].coverImage.url,
                            url: response.data.data.concertCollection.items[i].coverImage.url,
                            newUrl: stripImageUrl(response.data.data.concertCollection.items[i].coverImage.url),
                            desc: response.data.data.concertCollection.items[i].desc,
                            testDate: response.data.data.concertCollection.items[i].testDate
                        }
                    ]
                )


                arr.push({
                    title: response.data.data.concertCollection.items[i].bandName,
                    date: dateValues,
                    link: response.data.data.concertCollection.items[i].coverImage.url,
                    url: response.data.data.concertCollection.items[i].coverImage.url,
                    // url : url
                    desc: response.data.data.concertCollection.items[i].desc,
                    testDate: response.data.data.concertCollection.items[i].testDate
                })
            }
        }
        return arr;
    }

    function test2(arr) {
        // eslint-disable-next-line array-callback-return
        arr.map((item, i) => {
            const dateFormatted = moment(item.testDate).format('YYYY-MM-DD').toString()
            let el = document.querySelector(`td[data-date="${dateFormatted}"]`);
            if (el != null) {
                const state = {
                    coverImage: {
                        url: item.url,
                        title: item.title
                    },
                    bandName: item.title,
                    desc: item.desc,
                    testDate: item.testDate
                }
                el.classList.add("image-wrapper-box")
                // el.classList.add("d-flex")
                el.classList.add("padding-test")
                el.style.backgroundImage = `url('${item.url}')`
                // el.style.backgroundImage = `url('${item.link}')`
                el.addEventListener('click', function handleClick(event) {
                    navigate(`/gig/${dateFormatted}`, {state: state})
                    // console.log('element clicked 🎉🎉🎉', dateFormatted);
                });
            }
        })
    }

    function showPage() {
        setTimeout(() => {
            setShow(true)
        }, 1)
        // setIsLoading(false)
    }


    useEffect(() => {
        handleLoad()
        test2(allMappedConcerts)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        test2(allMappedConcerts);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [allMappedConcerts])

    useLayoutEffect(() => {
        handleLoad()
        showPage()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const showStyle = {
        visibility: "visible",
        transitionDelay: "0s",
        opacity: "1",
        marginTop: '8rem'
    }
    const hideStyle = {
        visibility: 'hidden',
        opacity: "0",
        transition: "visibility 0s linear 0.5s,opacity 0.5s linear"
    }

    const { i18n } = useTranslation();

    const changeLanguage = lng => {
        i18n.changeLanguage(lng);
        if(lng === "en")
            setLang(enLocale)
        else
            setLang(trLocale)
    };

    return (
        <Layout>
            <Navbar changeLanguage={changeLanguage}/>
            {isLoading
                ?
                (<div style={{width: '100vw', height: '100vh', zIndex: '9999'}}
                      className="does-it-exist d-flex justify-content-center align-items-center m-auto">
                    <Oval style={{justifyContent: 'center', width: '50px', height: '50px'}} stroke="#bf1d1d"
                          strokeOpacity={.7} speed={2}/>
                </div>)
                :
                <></>}


            <div  style={show ? showStyle : hideStyle} >
                {visible && (
                    <FullCalendar
                        height={1000}
                        fixedWeekCount={false}
                        plugins={[dayGridPlugin]}
                        locale={lang}
                        events={allMappedConcerts}
                    />
                )}
            </div>
        </Layout>
    );
}


const query = `
{
  concertCollection{
    items{
      bandName
      desc{
        json
      }
      table
      testDate
      resLimit
      coverImage {
        title
        url(transform: {
          width : 300
          height: 220
        })
      }
    }
  }
}`