export function convertContentfulStringDateToShortDate(elem) {
    return (new Date(elem)).toLocaleDateString('tr-TR').toString()
}

export function getDayFromContentfulStringDate(elem, local) {
    return (new Date(elem)).toLocaleDateString('tr-TR', {weekday: 'long'}).toUpperCase()
}


export function getClock(elem, local, flag) {
    if (flag != null) {
        var aa = (new Date(elem)).getHours().toString()
        return (parseInt(aa) - flag).toString() + ":" + addZero((new Date(elem)).getMinutes()).toString()
    } else {
        var aaa = (new Date(elem)).getHours().toString() + ":" + addZero((new Date(elem)).getMinutes()).toString()
        return aaa
    }

}

export function getDayAndMonth(elem) {
    let arr = []
    let date = convertContentfulStringDateToShortDate(elem)
    let day = date.getDate()
    let month = date.getMonth()
    let year = date.getYear()
    arr.push(day)
    arr.push(month)
    arr.push(year)
    return arr

}

export function convertContentfulStringDateToDate(elem) {
    return (new Date(elem))
}

function addZero(i) {
    if (i < 10) {
        i = "0" + i
    }
    return i;
}


export function setAdTitleStringLimit(text) {
    if (text !== null) {
        var count = text.length;

        if (count > 100) {
            return text.substring(0, 60) + "...";
        } else {
            return text;
        }
    } else {
        return null
    }

}

export function getImageSizeFromUrl(url) {
    var size = {width: 0, height: 0}
    var img = new Image();
    img.onload = function () {
        size.width = this.width
        size.height = this.height
    };
    img.src = url;
    return size
}

export function mutateImageUrl(url, width, height) {
    let ratio = (parseFloat(width) / parseFloat(height)).toFixed(2)
    let newWidth = 200;
    let newHeight = Math.round(ratio* newWidth);

    return `${url}?w=${newWidth}&h=${newHeight}`
}

export function stripImageUrl(url) {
    // var str="abc/";
    var newUrl = ""
    for (let i = 0; i < url.length; i++) {
        if (url[i] === "?"){
            return newUrl
        }
        newUrl += url[i].toString()
    }
    return newUrl

    // let ratio = (parseFloat(width) / parseFloat(height)).toFixed(2)
    // let newWidth = 200;
    // let newHeight = Math.round(ratio* newWidth);
    //
    // return `${url}?w=${newWidth}&h=${newHeight}`
}