import React, {useEffect} from 'react'
import {Outlet} from "react-router-dom";
import Layout from "../Components/Layout/Layout";

const Blogs = () => {

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])
    return (
        <Layout>
            <Outlet/>
        </Layout>
    )
}

export default Blogs