import React, {useEffect} from "react";
import {Outlet} from "react-router-dom";
import Layout from "../Layout/Layout";

const Concerts = () => {

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])
    return (
        <Layout>
            <Outlet/>
        </Layout>
    );
};

export default Concerts;
