import React from 'react'
// import InstagramEmbed from "react-instagram-embed";
import { InstagramEmbed } from 'react-social-media-embed';
import {useInstagramFeed} from "use-instagram-feed";
// import InstagramEmbed from "react-instagram-embed";

const InstagramFeed = () => {
    let photos = useInstagramFeed({
        userId: "553648129",
        thumbnailWidth: 640,
        photoCount: 1,
    })
    console.log('photos ',photos)
    return (
        <div>
            <div style={{ display: 'flex', justifyContent: 'center' }}>
                <InstagramEmbed url="https://www.instagram.com/p/CkKoeZFITOD/" width={328} />
            </div>
            {/*<InstagramGallery*/}
            {/*    accessToken="IGQVJWZATFrVFdub2RBbzdjS2hOTTZAqeTdHWHh1cDFGTUlpa2dKdUpsQ1JDZA3dSUTI0ekIwd2tTeUNxRm5sc3NDeGhCOTFoeFJCUklMN1l5ZA2wybnYyUEQ4SnBBZA0VJckdfVlRodGVGWFE1SWJ1TmFKNgZDZD"*/}
            {/*    count={6}/>*/}
            {/*<InstagramEmbed*/}
            {/*    url="https://www.instagram.com/bova_sahne/"*/}
            {/*    maxWidth={320}*/}
            {/*    hideCaption={true}*/}
            {/*    containerTagName="div"*/}
            {/*    protocol=""*/}
            {/*    injectScript*/}
            {/*/>*/}
        </div>
    )
}

export default InstagramFeed