import React, {useEffect, useState} from 'react'
import {protectedEnpointPostRequest} from "../static/functions";
import Layout from "../Components/Layout/Layout";
import '../assets/css/templatemo-xtra-blog.css'
import {convertContentfulStringDateToDate, mutateImageUrl, setAdTitleStringLimit} from "../static/helpers";
import moment from "moment";
import {Link} from "react-router-dom";
import Navbar from "../Components/Layout/Header/Navbar";

const BlogList = () => {
    // const {baseURL} = useContext(BaseURLContext);
    const [blogs, setBlogs] = useState([]);
    const body = JSON.stringify({query});
    const token = process.env.REACT_APP_ACCESS_TOKEN;

    async function getAllBlogs() {
        const response = await protectedEnpointPostRequest(
            `${process.env.REACT_APP_CONTENTFUL_API}/${process.env.REACT_APP_CONTENTFUL_SPACE_ID}/`,
            body,
            token
        )
        if (response) {
            setBlogs(response.data.data.blogsCollection.items)
            // console.log('blogs ',response.data.data.blogsCollection.items)
        }
    }

    useEffect(() => {
        getAllBlogs();
        //eslint-disable-next-line
    }, []);
    return (
        <Layout>
            <Navbar/>
            <div className="container-fluid mt-5">
                <div className="tm-main m-auto">
                    <div className="row tm-row m-auto">
                        {blogs && blogs.map((item, i) => {
                            return (
                                <article key={i} className="col-12 col-md-6 tm-post single-blog-post">
                                    <Link to={`/bova-new/blog/${item.slug}`} state={item} className=" tm-post-link mt-3">
                                        <div className="tm-post-link-inner image-wrapper-small">
                                            <img src={mutateImageUrl(item?.featureImage?.url,300,300)} alt="images attr"/>
                                        </div>
                                        <h2 className="tm-pt-30 tm-color-primary tm-post-title">
                                            {item?.title}
                                        </h2>
                                    </Link>
                                    <p className="tm-pt-30">
                                        {setAdTitleStringLimit(item?.description)}
                                    </p>
                                    <div className="d-flex justify-content-between tm-pt-45">
                                        <span className="tm-color-primary">{item?.articleDate ? moment(convertContentfulStringDateToDate(item?.articleDate)).format('MMMM d, YYYY') : ''}</span>
                                    </div>
                                    <hr/>
                                    <div className="d-flex justify-content-between">
                                        {/*<span>36 comments</span>*/}
                                        <span>Test</span>
                                    </div>
                                </article>
                            )
                        })}
                    </div>
                </div>
            </div>
        </Layout>
    )
}

export default BlogList


const query = `
query
    {
        blogsCollection
        {
            items
            {
                title
                slug
                description
                articleDate
                featureImage{
                    url
                }
            }
        }
    }
`
