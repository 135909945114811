import React, {useEffect, useState} from 'react'
import {useLocation, useParams} from "react-router-dom";
import moment from "moment";
import {DiscussionEmbed} from "disqus-react";
import {protectedEnpointPostRequest} from "../static/functions";
import Layout from "../Components/Layout/Layout";
import Share from "../Components/SocialShare";
import {stripImageUrl} from "../static/helpers";

const Blog = () => {
    const [blog, setBlog] = useState()

    // eslint-disable-next-line react-hooks/rules-of-hooks
    const {slug} = useParams();
    const {state} = useLocation();
    console.log('concert state will be injected with location hook', state)
    console.log('concert date with format LL ', slug)

    const disqusShortname = "RohitGupta";
    const disqusConfig = {
        identifier: slug,
        title: slug
    };

    useEffect(() => {
        window.scrollTo(0, 0)
        if (state === null) {
            getBlogData(slug)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {

    }, [])


    const siteurl = `${process.env.REACT_APP_URL}/gig`
    // this.props.state.contentfulSiteInformation.siteUrl + "/";
    const twiteerhandle = 'as'
    // this.props.state.contentfulSiteInformation.twiteerHandle;
    const socialConfigss = {
        site: {
            siteMetastate: {siteurl, twiteerhandle}
        },
        title: state?.title || blog?.title,
        slug: slug
    };
    // function buildQuery(slug) {
    //
    // }
    async function getBlogData(slug) {
        const query = `query
    {
        blogsCollection(where:{slug:"${slug.toString()}"})
        {
            items
            {
                title
                slug
                description
                articleDate
                featureImage{
                    url
                }
            }
        }
    }
`

        const body = JSON.stringify({query});
        const token = process.env.REACT_APP_ACCESS_TOKEN;
        const response = await protectedEnpointPostRequest(
            `${process.env.REACT_APP_CONTENTFUL_API}/${process.env.REACT_APP_CONTENTFUL_SPACE_ID}/`,
            body,
            token
        )
        if (response) {
            setBlog(response.data.data.blogsCollection.items[0])
        }
    }

    return (
        <Layout>
            <div className="mt-5 blog-post">
                {/*<div className="blog-post-container">*/}
                <div className="image-wrapper-box">
                    {(state?.featureImage?.url || blog?.featureImage?.url) ? (
                        <img
                            // style={{objectFit: 'cover'}}
                            alt={state?.articleDate || blog?.articleDate}
                            // src="https://images.ctfassets.net/pvnvm39tudgg/2IrICzqWccZg9hWRucCmpk/768f05bf728f98b94a0414fd92e6611e/278125610_10159557731463400_4716674247526174856_n.jpg"
                            src={stripImageUrl(state?.featureImage?.url || blog?.featureImage?.url)}
                        />
                    ) : (
                        <div className="no-image"></div>
                    )}
                </div>

                <div className="container mt-5">
                    <h1 className="title">{state?.title || blog?.title}</h1>

                    <div className="details">

                        <span className="date">
                <i className="fas fa-calendar-alt"></i>{" "}
                            {/*{state?.articleDate || blog?.articleDate}*/}
                            {(state?.articleDate || blog?.articleDate) && moment(state?.articleDate || blog?.articleDate).format("LL")}
              </span>
                        <div
                            // dangerouslySetInnerHTML={{
                            //     __html: state.description.childMarkdownRemark.html
                            // }}
                        />
                        <p>
                            {state?.description || blog?.description}
                        </p>
                    </div>
                    <Share
                        socialConfig={{
                            ...socialConfigss.site.siteMetastate.twiteerhandletitle,
                            config: {
                                url: `${siteurl}${socialConfigss.slug}`,
                                title: `${socialConfigss.title}`
                            }
                        }}
                    />
                    <DiscussionEmbed
                        shortname={disqusShortname}
                        config={disqusConfig}
                    />
                </div>
            </div>
        </Layout>
    )
}

export default Blog

//
// const query = (slug) => {
//     return `query
//     {
//         blogsCollection(where:{slug:"${slug.toString()}"})
//         {
//             items
//             {
//                 title
//                 slug
//                 description
//                 articleDate
//                 featureImage{
//                     url(transform:{
//                         width: 220,
//                         height: 250
//                     })
//                 }
//             }
//         }
//     }
// `
// }