import React, {Component} from "react";
import '../../assets/css/slider.css'
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import {Trans} from "react-i18next";

const responsive = {
    desktop: {
        breakpoint: {max: 3000, min: 1024},
        items: 3,
        slidesToSlide: 1 // optional, default to 1.
    },
    tablet: {
        breakpoint: {max: 1024, min: 464},
        items: 2,
        slidesToSlide: 1 // optional, default to 1.
    },
    mobile: {
        breakpoint: {max: 464, min: 0},
        items: 1,
        slidesToSlide: 1 // optional, default to 1.
    }
};

class Records extends Component {
    render() {
        return (
            <div style={{width: '100%', margin: '0 auto'}}>
                <h2 className="mb-3 text-black">
                    <Trans i18nKey="records">

                    </Trans>
                </h2>
                <div>
                    <Carousel
                        additionalTransfrom={0}
                        arrows
                        // autoPlaySpeed={3000}
                        centerMode
                        className="carousel-item-custom"
                        dotListClass=""
                        itemClass="carousel-item-custom"
                        draggable
                        focusOnSelect={false}
                        minimumTouchDrag={80}
                        pauseOnHover
                        renderArrowsWhenDisabled={false}
                        renderButtonGroupOutside={false}
                        renderDotsOutside={false}
                        responsive={responsive}
                        // ssr={true} // means to render carousel on server-side.
                        infinite={true}
                        // autoPlay={this.props.deviceType !== "mobile" ? true : false}
                        // autoPlaySpeed={1000}
                        autoPlay={false}
                        rewind={false}
                        rewindWithAnimation={false}
                        rtl={false}
                        shouldResetAutoplay
                        showDots={false}
                        sliderClass=""
                        slidesToSlide={1}
                        swipeable
                    >
                        <div className="">
                            <iframe src="https://www.youtube.com/embed/vzhPW7wr6mI"
                                    title="The 251 Soul x BOVA Sessions - Barış Doğukan Yazıcı Quartet"
                                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                    allowFullScreen></iframe>
                        </div>
                        <div className="">
                            <iframe src="https://www.youtube.com/embed/iulVkViNcvo"
                                    title="The 251 Soul x BOVA Sessions - Cenk Erdoğan Solo"
                                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                    allowFullScreen></iframe>
                        </div>
                        <div className="">
                            <iframe src="https://www.youtube.com/embed/x9G6-kEkokM"
                                    title="The 251 Soul x BOVA Sessions - Selen Gülün  Kadınlar Matinesi"
                                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                    allowFullScreen></iframe>
                        </div>
                        {/*<div className="p-2 w-100">*/}
                        <div className="">
                            <iframe
                                src="https://www.youtube.com/embed/OG08cHST2ho?list=PLXR6d33d9uNA2YomgPNpJlnR12e6bhbiq"
                                title="Can Tutuğ Quartet - Kırklareli [Live at BOVA]"
                                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                allowFullScreen></iframe>
                        </div>
                        <div className="">
                            <iframe
                                src="https://www.youtube.com/embed/OG08cHST2ho?list=PLXR6d33d9uNA2YomgPNpJlnR12e6bhbiq"
                                title="Can Tutuğ Quartet - Kırklareli [Live at BOVA]"
                                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                allowFullScreen></iframe>
                        </div>
                    </Carousel>
                </div>
            </div>
        );
    }
}

export default Records