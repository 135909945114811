import React, {useState} from 'react';
import {Document, Page, pdfjs} from 'react-pdf';
import '../assets/css/menuPdf.css'
import {AiOutlineArrowLeft, AiOutlineArrowRight} from "react-icons/ai";
import file from '../assets/pdf/BOVA QR MENUX_merged_S_XL_compressed.pdf'
import {useWindowSize} from "../static/hooks";


pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

const Menu = () => {
    const [numPages, setNumPages] = useState(null);
    const [pageNumber, setPageNumber] = useState(1);

    const onDocumentLoadSuccess = ({numPages}) => {
        setNumPages(numPages);
    };

    const goToPrevPage = () =>
        setPageNumber(pageNumber - 1 <= 1 ? 1 : pageNumber - 1);

    const goToNextPage = () =>
        setPageNumber(
            pageNumber + 1 >= numPages ? numPages : pageNumber + 1,
        );

    const sizes = useWindowSize()

    const goToPage = (pageNum) => {
        setPageNumber(pageNum)
        window.location.href = "#menuPdf";
    }

    return (
        <div className="bg-black">
            <div className="pt-3 bg-black m-auto text-center">
                <h2 className="text-white">
                    BOVA
                </h2>

                <h3 className="text-white">
                    MENU
                </h3>
                <div className="row">
                    <div className="col">
                        <ul className="list-inline d-flex flex-wrap justify-content-center">
                            <li className="list-inline-item">
                                <button
                                    style={{height:'70px'}}
                                    className="btn btn-light m-2 action-button"
                                    onClick={() => goToPage(1)}
                                >
                                    BEER
                                </button>
                            </li>
                            <li className="list-inline-item">
                                <button
                                    style={{height:'70px'}}
                                    className="btn btn-light m-2 action-button"
                                    onClick={() => goToPage(2)}
                                >
                                    SHOT
                                </button>
                            </li>
                            <li className="list-inline-item">
                                <button
                                    className="btn btn-light m-2  action-button"
                                    onClick={() => goToPage(3)}
                                    style={{height:'70px'}}
                                >
                                    GIN
                                </button>
                            </li>
                            <li className="list-inline-item">
                                <button
                                    className="btn btn-light m-2  action-button"
                                    onClick={() => goToPage(4)}
                                    style={{height:'70px'}}
                                >
                                    VODKA
                                </button>
                            </li>
                            <li className="list-inline-item">
                                <button
                                    className="btn btn-light m-2 action-button"
                                    onClick={() => goToPage(5)}
                                    style={{height:'70px'}}
                                >
                                    WHISKEY
                                </button>
                            </li>
                            <li className="list-inline-item">
                                <button
                                    className="btn btn-light m-2  action-button"
                                    onClick={() => goToPage(6)}
                                    style={{height:'70px'}}
                                >
                                    COGNACS & RUM & RAKI
                                </button>
                            </li>
                            <li className="list-inline-item">
                                <button
                                    className="btn btn-light m-2 action-button"
                                    onClick={() => goToPage(7)}
                                    style={{height:'70px'}}
                                >
                                    LIQUEUR
                                </button>
                            </li>
                            <li className="list-inline-item">
                                <button
                                    className="btn btn-light m-2 action-button"
                                    onClick={() => goToPage(8)}
                                    style={{height:'70px'}}
                                >
                                    WINE
                                </button>
                            </li>
                            <li className="list-inline-item">
                                <button
                                    className="btn btn-light m-2 action-button"
                                    onClick={() => goToPage(9)}
                                    style={{height:'70px'}}
                                >
                                    HOT & SOFT DRINKS
                                </button>
                            </li>
                            <li className="list-inline-item">
                                <button
                                    className="btn btn-light m-2 action-button"
                                    onClick={() => goToPage(10)}
                                    style={{height:'70px'}}
                                >
                                    SIGNATURE COCKTAILS
                                </button>
                            </li>
                            <li className="list-inline-item">
                                <button
                                    className="btn btn-light m-2 action-button"
                                    onClick={() => goToPage(11)}
                                    style={{height:'70px'}}
                                >
                                    CLASSIC COCKTAILS
                                </button>
                            </li>
                            <li className="list-inline-item">
                                <button
                                    className="btn btn-light m-2 action-button"
                                    onClick={() => goToPage(12)}
                                    style={{height:'70px'}}
                                >
                                    SNACKS
                                </button>
                            </li>
                            <li className="list-inline-item">
                                <button
                                    className="btn btn-light m-2 action-button"
                                    onClick={() => goToPage(13)}
                                    style={{height:'70px'}}
                                >
                                    BURGER
                                </button>
                            </li>
                            <li className="list-inline-item">
                                <button
                                    className="btn btn-light m-2 action-button"
                                    onClick={() => goToPage(14)}
                                    style={{height:'70px'}}
                                >
                                    MAIN DISHES
                                </button>
                            </li>
                            <li className="list-inline-item">
                                <button
                                    className="btn btn-light m-2 action-button"
                                    onClick={() => goToPage(15)}
                                    style={{height:'70px'}}
                                >
                                    PASTA
                                </button>
                            </li>
                            <li className="list-inline-item">
                                <button
                                    className="btn btn-light m-2 action-button"
                                    onClick={() => goToPage(16)}
                                    style={{height:'70px'}}
                                >
                                    DISH OF THE DAY
                                </button>
                            </li>
                            <li className="list-inline-item">
                                <button
                                    className="btn btn-light m-2 action-button"
                                    onClick={() => goToPage(17)}
                                    style={{height:'70px'}}
                                >
                                    SALAD
                                </button>
                            </li>
                        </ul>
                    </div>
                </div>

                <nav>
                    <button onClick={goToPrevPage}>
                        <AiOutlineArrowLeft/>
                    </button>

                    <button onClick={goToNextPage}>
                        <AiOutlineArrowRight/>
                    </button>
                    <p className="text-white m-2">
                        Page {pageNumber} of {numPages}
                    </p>
                </nav>

                {pdfjs &&
                    <div
                        id="menuPdf"
                        className=" d-flex justify-content-center align-items-center"
                        style={{width: '100%'}}
                    >
                        {sizes.width > 680
                            ?

                            <Document
                                file={file}
                                onLoadSuccess={onDocumentLoadSuccess}
                            >
                                <Page pageNumber={pageNumber}/>
                            </Document>
                            :
                            <Document
                                file={file}
                                onLoadSuccess={onDocumentLoadSuccess}
                            >
                                <Page pageNumber={pageNumber}/>
                            </Document>

                        }
                    </div>}
            </div>
        </div>
    );
};

export default Menu;