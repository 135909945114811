import React, {useEffect, useState} from 'react'
import {useLocation, useParams} from "react-router-dom";
import moment from "moment";
import {documentToReactComponents} from "@contentful/rich-text-react-renderer";
import Layout from "../Layout/Layout";
import Share from "../SocialShare";
import {protectedEnpointPostRequest} from "../../static/functions";
import {DiscussionEmbed} from "disqus-react";
import {convertContentfulStringDateToDate, stripImageUrl} from "../../static/helpers";
import '../../assets/css/gig.css'
import Navbar from "../Layout/Header/Navbar";
import {useTranslation} from "react-i18next";

const Gig = () => {
    const [data, setData] = useState()

    // eslint-disable-next-line react-hooks/rules-of-hooks
    const {slug} = useParams();
    const {state} = useLocation();
    console.log('get desc ',state)
    const disqusShortname = "RohitGupta";
    const disqusConfig = {
        identifier: slug,
        title: slug
    };

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    useEffect(() => {
        if (state == null) {
            getGigData()
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const siteurl = `${process.env.REACT_APP_URL}/gig`
    // this.props.state.contentfulSiteInformation.siteUrl + "/";
    const twiteerhandle = 'as'
    // this.props.state.contentfulSiteInformation.twiteerHandle;
    const socialConfigss = {
        site: {
            siteMetastate: {siteurl, twiteerhandle}
        },
        title: state?.bandName || data?.bandName,
        slug: slug
    };

    async function getGigData() {
        const body = JSON.stringify({query});
        const token = process.env.REACT_APP_ACCESS_TOKEN;
        const response = await protectedEnpointPostRequest(
            `${process.env.REACT_APP_CONTENTFUL_API}/${process.env.REACT_APP_CONTENTFUL_SPACE_ID}/`,
            body,
            token
        )

        if (response) {
            for (let i = 0; i < response?.data?.data?.concertCollection?.items?.length; i++) {
                const newDate = convertContentfulStringDateToDate(response?.data?.data?.concertCollection?.items[i]?.testDate)
                    // new Date(parseInt(response.data.data.concertCollection.items[i].date),
                    // 7,
                    // parseInt(response.data.data.concertCollection.items[i].day))
                const dateFormatted = moment(newDate).format('YYYY-MM-DD').toString()
                console.log('test desc', response.data)
                if (dateFormatted === slug) {
                    setData(
                        {
                            bandName: response?.data?.data?.concertCollection?.items[i]?.bandName,
                            newUrl: stripImageUrl(response?.data?.data?.concertCollection?.items[i]?.coverImage?.url),
                            desc: response?.data?.data?.concertCollection?.items[i]?.desc,
                            testDate: dateFormatted,
                        }
                    )
                }
            }
        }
    }

    const { i18n } = useTranslation();

    const changeLanguage = lng => {
        i18n.changeLanguage(lng);
    };

    useEffect(() => {
        window.scrollTo(0,0)
    }, [])

    return (
        <Layout>
            <Navbar changeLanguage={changeLanguage}/>
            <div className="mt-5 blog-post">
                <div className="blog-post-container">
                    <div className="image-wrapper-2">

                        {(state?.coverImage?.url || data?.newUrl) ? (
                            <img
                                style={{objectFit:'cover'}}
                                className="feature-img"
                                alt={state?.bandName || data?.bandName}
                                src={stripImageUrl(state?.coverImage?.url || data?.newUrl)}
                            />
                        ) : (
                            <div className="no-image"></div>
                        )}
                    </div>
                </div>
                <div className="container mt-5 m-auto gig-wrapper">
                    {/*<h1 className="title">{state?.bandName || data?.bandName}</h1>*/}
                    <div className="section-head">
                        <h2 className="text-black">{state?.bandName || data?.bandName}</h2>
                    </div>
                    <div className="details">
                        <span className="date">
                <i className="fas fa-calendar-alt"></i>{" "}
                            {slug}
                            {/*{moment(state?.testDate).format("LL")}*/}
              </span>
                        <div className=""/>
                        <p style={{
                            color:'black !important'
                        }}>
                            {documentToReactComponents(state?.desc?.json || data?.desc?.json)}
                        </p>
                    </div>
                    <Share
                        socialConfig={{
                            ...socialConfigss.site.siteMetastate.twiteerhandletitle,
                            config: {
                                url: `${siteurl}${socialConfigss.slug}`,
                                title: `${socialConfigss.title}`
                            }
                        }}
                    />
                    <DiscussionEmbed
                        shortname={disqusShortname}
                        config={disqusConfig}
                    />
                </div>
            </div>
        </Layout>
    )
}

export default Gig

const query = `
{
  concertCollection{
    items{
      bandName
      desc
      month
      year
      day
      testDate
      coverImage {
        title
        url(transform: {
          width : 220
          height: 250
        })
      }
    }
  }
}`