import React from 'react'
import {Link} from "react-router-dom";
import {Trans} from "react-i18next";

const BlogListSmall = ({blogs}) => {

    return (
        <div className="card shadow mb-4">
            <div className="card-body p-5">
                <div className="section-head">
                    <h2 className="text-black">

                        <Trans i18nKey="onPress">

                        </Trans>

                    </h2>
                </div>
                <ul className="list-bullets">
                    {blogs && blogs.map((item, i) => {
                        return (
                            <li className="mb-2" key={i}>
                                <Link className="small-list-row" to={`/bova-new/blog/${item.slug}`}>
                                {item.title}
                                </Link>
                            </li>

                        )
                    })}
                </ul>
            </div>
        </div>
    )
}

export default BlogListSmall