import React from 'react';
import MainImage from '../assets/images/cover_1.jpg'
import '../assets/css/main.css'

const TempMain = () => (
    <div className="m-auto  ">
        <div className="section-1">
            <div className="image-wrapper">
                <img src={MainImage} alt=""/>
                <h2 style={{fontSize:'3em',lineHeight:'1.2em'}} className="text-white text-center m-5">UNDER CONSTRUCTION</h2>
            </div>
        </div>

    </div>
);


export default TempMain;