import React from 'react'
// import Navbar from './Header/Navbar'
// import Footer from "../Footer";
import { motion } from "framer-motion";
import '../../assets/css/main.css'

const Layout = ({children}) => {

    return (
        <motion.div
            // className="text-center"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            transition={{ duration: 1 }}
        >
            {/*<Navbar/>*/}
            {children}
            {/*<Footer/>*/}
        </motion.div>

    )
}


export default Layout