import React from 'react'
import '../assets/css/footer.css'
import Logo from '../assets/images/LOGO_INVERTED.png'
import {Trans} from "react-i18next";

const Footer = () => {
    return (
        <div className="">
            <footer className="site-footer" id="footer">
                <div className="footer-container mt-3 mb-3">
                    <div className="row">
                        <div className="col-md-3">
                            <h6>
                                <Trans i18nKey="footerHeader">
                                    CONTACT US
                                </Trans>
                            </h6>
                            <p>
                                <a href="https://goo.gl/maps/XU98WPUjGDBiCPEe8" rel="noreferrer">
                                    Şehit Muhtar, Mis Sk. No:17, 34435 Beyoğlu/İstanbul
                                </a>
                            </p>
                            <p>
                                <a href="mailto:bovasahne@gmail.com" rel="noreferrer">
                                    bovasahne@gmail.com
                                </a>
                            </p>
                            <a href="tel:902122434461" rel="noreferrer">
                                +90 212 243 44 61
                            </a>
                        </div>
                        <div className="col-md-3">
                            <div>
                                <img src={Logo} alt="logo"/>
                            </div>
                        </div>
                        <div className="col-md-5 ">
                            <iframe
                                aria-label="location-1"
                                aria-labelledby="location-1"
                                title="location-1"
                                src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d12038.017229391524!2d28.9815485!3d41.0360995!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x81436739d60da73b!2sBova%20Jazz%20Club!5e0!3m2!1str!2str!4v1661339956780!5m2!1str!2str"
                                style={{width: '90%', height: '400px'}}
                                allowFullScreen="" loading="lazy"
                                referrerPolicy="no-referrer-when-downgrade"></iframe>
                        </div>
                    </div>
                </div>

            </footer>
        </div>
    )
}

export default Footer