import React from 'react'
import {Link} from "react-router-dom";
import {useWindowSize} from "../../static/hooks";
import {stripImageUrl} from "../../static/helpers";
import {Trans} from "react-i18next";
import moment from "moment/moment";

const CalendarIndex = ({events}) => {
    const size = useWindowSize()

    function formatDate(date) {
        return moment(date).format('YYYY-MM-DD').toString()
    }

    // const url = "https://images.ctfassets.net/pvnvm39tudgg/2IrICzqWccZg9hWRucCmpk/768f05bf728f98b94a0414fd92e6611e/278125610_10159557731463400_4716674247526174856_n.jpg"
    return (
        <div className="blogs-section m-auto" id="Blogs" style={{maxWidth: '950px'}}>
            <div className="container ">
                <div className="section-head">
                    <h2 className="text-black">
                        <Trans i18nKey="allEvents">
                        </Trans>
                    </h2>
                </div>
                <ul className={`blogs-list ${events.length < 5 ? "few-blogs" : ""}`}>
                    {/* eslint-disable-next-line array-callback-return */}
                    {events.map((item, index) => {
                        if (index < (size?.width < 680 ? 3 : 5))
                            return (
                                <li key={index} className="item">
                                    <div className="inner">
                                        <Link className="link"
                                           // onClick={() => {navigateTo(item)}}
                                           to={`/gig/${formatDate(item?.testDate)}`}
                                           state={item}
                                        />
                                        {item.coverImage.url ? (
                                            <img alt={item?.node?.slug} src={stripImageUrl(item.coverImage.url)}/>
                                        ) : (
                                            <div className="no-image"></div>
                                        )}
                                        <div className="details">
                                            <h3 className="title">{item.bandName}</h3>
                                            <span className="date">
                                                <i className="fas fa-calendar-alt"></i>{" "}
                                                {formatDate(item.testDate)}
                                                {/*{convertContentfulStringDateToShortDate()}*/}
                                            </span>
                                        </div>
                                    </div>
                                </li>
                            );
                    })}
                </ul>
                <div className="see-more">
                    <Link to="/takvim">
                        <span>
                            <Trans i18nKey="allEvents">

                            </Trans>
                            </span>
                    </Link>
                </div>
            </div>
        </div>
    )
}

export default CalendarIndex
